import React from "react"
import CheckoutLayout from "@utils/checkoutlayout"
import SEO from "@utils/seo"
import SectionCheckout from "@molecules/sectionCheckout"

const checkout = () => {
  return (
    <CheckoutLayout>
      <SEO title="Checkout" />
      <SectionCheckout />
    </CheckoutLayout>
  )
}

export default checkout
